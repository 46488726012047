import type { Address, Auction, Cart, Customer, LineItem, Order, Product } from '@medusajs/medusa';
import type { PricedProduct } from '@medusajs/medusa/dist/types/pricing';
import { useEffect, useState } from 'react';
import { getMinimumProductPriceValue } from '../prices';
import { isOrder } from '../types/orders';
import type { Entity } from './events';
import { isSegmentLoaded } from './segment/segment';
import type { GA4ItemParams } from './types';

export interface GaIds {
  clientId: string;
  measurementId: string;
}

export interface KlaviyoActionsProfile {
  email: string;
  first_name: string;
  last_name: string;
}

export const selectValueFromCart = (cart?: Cart) => (cart?.total ? cart.total / 100 : undefined);

export const selectValueFromOrder = (order?: Order) => (order?.total ? order.total / 100 : undefined);

export const getUpdatedCartValue = (updatedCart: Cart, previousCart?: Cart) => {
  return typeof updatedCart.total === 'number' && typeof previousCart?.total === 'number'
    ? (updatedCart.total - (previousCart?.total || 0)) / 100
    : undefined;
};

export const lineItemMap = (item: LineItem) => ({
  item_id: item.id,
  item_name: item?.title || item.variant?.title,
  item_category: item.variant?.product?.collection_id || undefined,
  item_variant: item.variant_id || undefined,
  price: item.total ? item.total / 100 : undefined,
  quantity: item.quantity,
  discount: item.discount_total ? item.discount_total / 100 : undefined,
  // item_category2: product.collection, // Placeholder for future use
  // item_category3: product.category, // Placeholder for future use
  // item_category4: product.subcategory, // Placeholder for future use
  // item_category5: product.subsubcategory, // Placeholder for future use
});

export const getItemsFromProducts: (product: (Product | PricedProduct)[], regionCurrency: string) => GA4ItemParams[] = (
  products,
  regionCurrency,
) => {
  return products.map((product) => ({
    item_id: product.id || '',
    item_name: product.title || '',
    item_category: product.collection_id || undefined,
    // item_category2: product.collection,
    // item_category3: product.category,
    // item_category4: product.subcategory,
    // item_category5: product.subsubcategory,
    price: getMinimumProductPriceValue(product as PricedProduct, regionCurrency) / 100,
  }));
};

export const getAddedCartItems: (updatedCart: Cart, previousCart?: Cart) => GA4ItemParams[] = (
  updatedCart,
  previousCart,
) => {
  return updatedCart.items
    .filter((item) => !previousCart?.items.find((prevItem) => prevItem.created_at === item.created_at))
    .map(lineItemMap);
};

export const getRemovedCartItems: (updatedCart: Cart, previousCart?: Cart) => GA4ItemParams[] = (
  updatedCart,
  previousCart,
) => {
  return (previousCart?.items || [])
    .filter((item) => !updatedCart.items.find((prevItem) => prevItem.created_at === item.created_at))
    .map(lineItemMap);
};

export const identifySegmentUser = async (customer: Omit<Partial<Customer>, 'password_hash'>) => {
  if (!isSegmentLoaded()) throw new Error('Segment is not loaded.');

  return window.segmentAnalytics.identify(customer.id || customer.email, {
    email: customer.email,
    firstName: customer.first_name || '',
    lastName: customer.last_name || '',
    'External ID': customer.id,
    'First Name': customer.first_name || '',
    'Last Name': customer.last_name || '',
    'Phone Number': customer.phone || '',
  });
};

export const selectCheckoutDataFromCart = (cart: Cart) => ({
  value: selectValueFromCart(cart) as number,
  currency: cart.region.currency_code,
  items: cart.items.map(lineItemMap),
  shipping: (cart.shipping_total || 0) / 100,
  tax: (cart.tax_total || 0) / 100,
  discount: (cart.discount_total || 0) / 100,
  coupon: cart.discounts.map((dc) => dc.code).join(','),
});

export const selectCheckoutDataFromOrder = (order: Order) => ({
  value: selectValueFromOrder(order) as number,
  currency: order.currency_code,
  items: order.items.map(lineItemMap),
  shipping: (order.shipping_total || 0) / 100,
  tax: (order.tax_total || 0) / 100,
  discount: (order.discount_total || 0) / 100,
  coupon: order.discounts.map((dc) => dc.code).join(','),
});

export const selectCheckoutDataFromEntity = (entity: Entity) => {
  const bid = isOrder(entity) ? entity.cart?.bid : (entity as Cart).bid;

  return {
    value: bid.bid_amount / 100,
    currency: bid.currency_code,
    items: entity.items.map(lineItemMap),
    shipping: (entity.shipping_total || 0) / 100,
    tax: (entity.tax_total || 0) / 100,
    discount: (entity.discount_total || 0) / 100,
    coupon: entity.discounts.map((dc) => dc.code).join(','),
  };
};

export const selectProductDataFromAuction = (auction: Auction | Auction) => {
  const primaryVariant = auction.product.variants[0];
  const tags = auction.product.tags.map((tag) => tag.value);

  return {
    product_id: auction.product.id,
    sku: primaryVariant.sku,
    current_price: auction.current_price,
    variant_id: primaryVariant.id,
    inventory_count: primaryVariant.inventory_quantity,
    images: auction.product.images,
    type: auction.product.type,
    collection: auction.product.collection_id,
    tags,
    created_at: auction.product.created_at,
    updated_at: auction.product.updated_at,
    title: auction.product.title,
    subtitle: auction.product.subtitle,
    handle: auction.product.handle,
  };
};

const lineItemMapper = (items: LineItem[], currency_code: string) => {
  return items?.length
    ? items.map((item) => {
        const primaryVariant = item.variant;

        if (!primaryVariant) {
          return item;
        }

        const product = primaryVariant.product ?? item;
        const tags = product?.tags?.map((tag) => tag.value) ?? [];

        return {
          product_id: item.product_id,
          sku: primaryVariant.sku,
          currency_code: currency_code,
          current_price: item.unit_price,
          variant_id: primaryVariant.id,
          inventory_count: primaryVariant.inventory_quantity,
          images: product.images,
          type: product.type,
          collection: product.collection_id,
          tags: tags,
          created_at: product.created_at,
          updated_at: product.updated_at,
          title: product.title,
          subtitle: product.subtitle,
          handle: product.handle,
        };
      })[0]
    : {};
};

export const selectProductDataFromOrder = (order: Order) => {
  return lineItemMapper(order.items, order.currency_code);
};

export const selectProductDataFromCart = (cart: Cart) => {
  if (cart.bid?.auction) return selectProductDataFromAuction(cart.bid.auction);

  return lineItemMapper(cart.items, cart.region.currency_code);
};

export const setUserDataFromCustomer = (customer?: Customer) => {
  if (!customer) return;

  const normalizeEmail = (email: string) => {
    let normalizedEmail = email.toLowerCase().replace(/\s+/g, '');
    if (normalizedEmail.endsWith('@gmail.com')) normalizedEmail = normalizedEmail.replace(/\./g, '');
    return normalizedEmail;
  };

  const normalizePhoneNumber = (phoneNumber: string) => `${phoneNumber.replace(/\D/g, '')}`;

  const normalizeString = (str: string) =>
    str
      .toLowerCase()
      .replace(/[\d\s\W_]+/g, '')
      .trim();

  const normalizeAddress = (address: Address) => ({
    first_name: normalizeString(address.first_name ?? ''),
    last_name: normalizeString(address.last_name ?? ''),
    street: normalizeString(address.address_1 ?? ''),
    city: normalizeString(address.city ?? ''),
    region: normalizeString(address.province ?? ''),
    postal_code: address.postal_code?.replace(/[.~]/g, '').trim(),
    country: (address.country?.iso_2 ?? '').toUpperCase(),
  });

  const user_data = {
    email_address: customer.email ? [normalizeEmail(customer.email)] : [],
    phone_number: customer.phone ? [normalizePhoneNumber(customer.phone)] : [],
    address: customer.shipping_addresses ? customer.shipping_addresses.map(normalizeAddress) : [],
  };

  gtag('set', 'user_data', user_data);
};

export const gtagClientIdsPromise = (): Promise<GaIds[]> => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return Promise.reject('No dataLayer found');
  }
  const gtagMeasurementIds = window.dataLayer
    .filter((item: Gtag.GtagCommands['config']) => item[0] === 'config')
    .map((item: Gtag.GtagCommands['config']) => item[1]);

  return Promise.all(
    gtagMeasurementIds.map(
      (measurementId: Gtag.CustomParams['config'][1]) =>
        new Promise((resolve, reject) => {
          gtag('get', String(measurementId), 'client_id', (clientId: Gtag.CustomParams['get'][2]) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            !clientId ? reject(`Failed to get client_id for ${measurementId}`) : resolve({ clientId, measurementId }),
          );
        }),
    ),
  ) as Promise<GaIds[]>;
};

// Note: these variables should allow use to cache client IDs in memory avoiding multiples of these hooks from running multiple times
// https://chatgpt.com/share/e/34bfe435-82cb-4e9f-a267-7c4d79c18a5b
let cachedClientIds: { clientId: string; measurementId: string }[] | null = null;
let cachedError: string | null = null;
let isFetching = false;
export const useGtagClientIds = () => {
  const [clientIds, setClientIds] = useState<{ clientId: string; measurementId: string }[] | null>(cachedClientIds);
  const [error, setError] = useState<string | null>(cachedError);

  useEffect(() => {
    if (cachedClientIds || cachedError || isFetching) {
      return;
    }

    const fetchClientIds = async () => {
      try {
        isFetching = true;
        const ids = await gtagClientIdsPromise();
        cachedClientIds = ids;
        setClientIds(ids);
      } catch (err) {
        cachedError = err as string;
        setError(err as string);
      } finally {
        isFetching = false;
      }
    };

    void fetchClientIds();
  }, []);

  return { clientIds: clientIds ?? [], error };
};

export const extractProfileFromOrder = (order: Order) => {
  return {
    email: order.email ?? '',
    first_name: (order.customer?.first_name || order.shipping_address?.first_name) ?? '',
    last_name: (order.customer?.last_name || order.shipping_address?.last_name) ?? '',
  };
};

export const extractProfileFromCart = (cart: Cart) => {
  return {
    email: cart.email ?? cart.customer?.email ?? '',
    first_name: cart.customer?.first_name ?? cart.shipping_address?.first_name ?? '',
    last_name: cart.customer?.last_name ?? cart.shipping_address?.last_name ?? '',
  };
};
